import React, { useState, useEffect, useRef } from 'react';
import { Modal, Divider } from 'antd';

import '../index.css';
import Layoutas from '../../components/Layout';
import Mapbox from '../../mapbox'
import LegendCard from '../../mapbox/LegendCard'
// import Sidetab from './Sidetab'
import Sidetab from '../../components/Sidetab'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import _province from "../election_map/model/_provinces.json";
import Calculate from "../../util/calculate";
import Summary from './map_fsql_contents/Summary';
import Area from './map_fsql_contents/Area';

import marker_obec from '/images/marker/dot-obec.svg'
import marker_dla from '/images/marker/dot-dla.svg'
import marker_bpp from '/images/marker/dot-bpp.svg'
import marker_onab from '/images/marker/dot-onab.svg'
import marker_opec from '/images/marker/dot-opec.svg'
import api from '../../util/api';
import getProvinceName from '../../util/getProvinceName'
// const border_color = ["#1b5e20", "#388e3c", "#66bb6a", "#a5d6a7", "#a5d6a7"];
const border_color = ["#1b5e20", "#388e3c", "#66bb6a", "#94a3b8", "#94a3b8"];



const _genColorRange = (color_set, value) => {
  color_set = color_set.sort((a, b) => b.value - a.value);
  let color = "";
  for (let i in color_set) {
    if (value > color_set[i].value) {
      color = color_set[i].color;
      break;
    }
  }
  return color;
};


const getDataSummary = (data) => {

  var result = [];

  data.reduce(function (res, value) {
    if (!res[value.size]) {
      res[value.size] = { size: value.size, school: 0 };
      result.push(res[value.size]);
    }
    res[value.size].school += 1;
    return res;
  }, {});


  return result
}

const getDataMap = (data) => {

  var result = [];

  data.reduce(function (res, value) {
    if (!res[value.provinceid]) {
      res[value.provinceid] = { provinceid: value.provinceid, province: value.province, school: 0 };
      result.push(res[value.provinceid]);
    }
    res[value.provinceid].school += 1;
    return res;
  }, {});



  _province.forEach(item => {

    let tmp = result.find(x => x.provinceid === item.id.toString())

    if (!tmp) {

      tmp = { provinceid: item.id, province: item.name, school: 0 }
      result.push(tmp)
    }

  });

  console.log("result", result);


  // let tmpData = _province.map(item => {
  //   let objProvince = data.find(x => x.province === item.name)

  //   if(!objProvince) objProvince = {}

  //   return {...{provinceid:item?.id?.toString(),color: "#cfcfcf"},...objProvince}
  // });


  // console.log("tmpData",tmpData);

  let resultMap = result.map((item, idx) => {
    return { ...item, ...{ color: "#cfcfcf" } }
  });


  resultMap = resultMap.map((item, idx) => {
    return {
      ...item,
      ...{ percent: (item.school / data.length) * 100 },
    };
  });

  let data_color = [
    {
      color: border_color[0],
      value: Calculate.Percentile(
        90,
        resultMap.map((item) => item.school)
      ),
      count: 0,
      text: `มาก`,
    },
    {
      color: border_color[1],
      value: Calculate.Percentile(
        50,
        resultMap.map((item) => item.school)
      ),
      count: 0,
      text: `ปานกลาง`,
    },
    {
      color: border_color[2],
      value: Calculate.Percentile(
        10,
        resultMap.map((item) => item.school)
      ),
      count: 0,
      text: `ปานกลาง`,
    },
    { color: border_color[3], value: 0, count: 0, text: `น้อย` },
    { color: border_color[4], value: -100, count: 0, text: `น้อย` },
  ];



  resultMap = resultMap.map((item, idx) => {

    return {
      ...item,
      ...{
        province_name: item.province,
        city: item.city_name ? item.city_name : null,
        percent: parseFloat(item.percent.toFixed(2)),
        color: _genColorRange(data_color, item.school),
      },
    };
  });



  console.log("resultMap", resultMap);


  return resultMap



}

const getDataArea = (data) => {

  let obj = { 
    schoolType: {
      average: 0,
      advantaged: 0,
      disAdvantaged: 0
    }, 
    studentType: {
      average: 0,
      advantaged: 0,
      disAdvantaged: 0
    }, 
    schoolSizeType: { 
      small: {
        average: 0,
        advantaged: 0,
        disAdvantaged: 0
      }, 
      middle: {
        average: 0,
        advantaged: 0,
        disAdvantaged: 0
      }, 
      large: {
        average: 0,
        advantaged: 0,
        disAdvantaged: 0
      }, 
      extra: {
        average: 0,
        advantaged: 0,
        disAdvantaged: 0
      }, 
    }, 
    schoolOutcome: { 
      high: {
        average: 0,
        advantaged: 0,
        disAdvantaged: 0
      }, 
      average: {
        average: 0,
        advantaged: 0,
        disAdvantaged: 0
      }, 
      low: {
        average: 0,
        advantaged: 0,
        disAdvantaged: 0
      } 
    } 
  }

  let mapSchoolType = ['advantaged', 'average', 'disAdvantaged']


  data.forEach(item => {
    if (!obj.schoolType[mapSchoolType[item.kmgroup - 1]]) obj.schoolType[mapSchoolType[item.kmgroup - 1]] = 0
    if (!obj.studentType[mapSchoolType[item.kmgroup - 1]]) obj.studentType[mapSchoolType[item.kmgroup - 1]] = 0

    if (item.size === 'เล็ก') {
      if (!obj.schoolSizeType['small'][mapSchoolType[item.kmgroup - 1]]) obj.schoolSizeType['small'][mapSchoolType[item.kmgroup - 1]] = 0
      obj.schoolSizeType['small'][mapSchoolType[item.kmgroup - 1]] += 1
    }

    if (item.size === 'กลาง') {
      if (!obj.schoolSizeType['middle'][mapSchoolType[item.kmgroup - 1]]) obj.schoolSizeType['middle'][mapSchoolType[item.kmgroup - 1]] = 0
      obj.schoolSizeType['middle'][mapSchoolType[item.kmgroup - 1]] += 1
    }

    if (item.size === 'ใหญ่') {
      if (!obj.schoolSizeType['large'][mapSchoolType[item.kmgroup - 1]]) obj.schoolSizeType['large'][mapSchoolType[item.kmgroup - 1]] = 0
      obj.schoolSizeType['large'][mapSchoolType[item.kmgroup - 1]] += 1
    }

    if (item.size === 'ใหญ่พิเศษ') {
      if (!obj.schoolSizeType['extra'][mapSchoolType[item.kmgroup - 1]]) obj.schoolSizeType['extra'][mapSchoolType[item.kmgroup - 1]] = 0
      obj.schoolSizeType['extra'][mapSchoolType[item.kmgroup - 1]] += 1
    }

    if (item.outcomegroup === 1) {
      if (!obj.schoolOutcome['high'][mapSchoolType[item.kmgroup - 1]]) obj.schoolOutcome['high'][mapSchoolType[item.kmgroup - 1]] = 0
      obj.schoolOutcome['high'][mapSchoolType[item.kmgroup - 1]] += 1
    }

    if (item.outcomegroup === 2) {
      if (!obj.schoolOutcome['average'][mapSchoolType[item.kmgroup - 1]]) obj.schoolOutcome['average'][mapSchoolType[item.kmgroup - 1]] = 0
      obj.schoolOutcome['average'][mapSchoolType[item.kmgroup - 1]] += 1
    }

    if (item.outcomegroup === 3) {
      if (!obj.schoolOutcome['low'][mapSchoolType[item.kmgroup - 1]]) obj.schoolOutcome['low'][mapSchoolType[item.kmgroup - 1]] = 0
      obj.schoolOutcome['low'][mapSchoolType[item.kmgroup - 1]] += 1
    }

    obj.schoolType[mapSchoolType[item.kmgroup - 1]] += 1
    obj.studentType[mapSchoolType[item.kmgroup - 1]] += item.stu_all
  });


  return obj

}


const FullMap = (props) => {
  const [open, setOpen] = useState(true)
  const [data, setData] = useState([])
  const [dataSummary, setDataSummary] = useState([])
  const [dataArea, setDataArea] = useState({})
  const [student, setStudent] = useState(0)
  const [tabvalue, setTabValue] = React.useState(0);
  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#a5d6a7',])
  const is_Click = useRef(false);
  const [filter, setFilter] = React.useState({
    minView: "country",
    view: 'country',
    year: "2565",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
  });

  const [modalSchool, setModalSchool] = useState({
    visible: false,
    resource: null
  });

  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: ''
  })

  // useEffect(async () => {
  //   let res_fsql = await api.getInitData('fsql')
  //   let is_login = api.isLogin().status
  //   let res_profile = await (is_login ? api.getProfileDb() : null)
  //   console.log('res_fsql',res_fsql)
  //   if (res_profile) {
  //     if (res_profile?.profile?.roles?.includes("partner_province") && api.getActiveRole() === "partner_province") {
  //       let pid = res_profile?.profile?.profile?.provinceId
  //       setData(res_fsql.deepdata.data.filter(item => item.province === getProvinceName(pid)))
  //       setFilter({ ...filter, minView : "province",view: "province", province_name: getProvinceName(pid) })
  //     } else {
  //       setData(res_fsql.deepdata.data)
  //     }
  //   }
  // }, []);

  useEffect(async () => {
    let res_fsql = await api.getInitData('fsql')
    setData(res_fsql.deepdata.data)
  }, [])

  useEffect(() => {
    let tmp = getDataSummary(data)
    let dataMap = getDataMap(data)
    let dataArea = getDataArea(data)
    let studentCount = 0

    data.forEach(item => {
      studentCount += item.stu_all
    });

    setStudent(studentCount)

    setDataSummary(tmp)
    setDataArea(dataArea)
    setDataMap([
      { id: "border", layer: 'country', data: dataMap },
      { id: 'school', layer: 'marker', data: data.map(item => ({ ...item, type: 'school' })).filter(x => x.kmgroup === 1), icon: marker_obec },
      { id: 'school2', layer: 'marker', data: data.map(item => ({ ...item, type: 'school' })).filter(x => x.kmgroup === 2), icon: marker_bpp },
      { id: 'school3', layer: 'marker', data: data.map(item => ({ ...item, type: 'school' })).filter(x => x.kmgroup === 3), icon: marker_opec }
    ])
    let data_legend = [
      { name: 'จำนวนโรงเรียน', value: dataMap.reduce((acc, cur) => acc + cur.school, 0), unit: 'โรงเรียน' },
    ]
    setLegendData({
      ...legend_data,
      title: filter.province_name,
      subtitle: filter.city_name,
      data: data_legend,
    })
  }, [data])

  let tab_content = [
    {
      title: 'ภาพรวม',
      content: <Summary mainData={data} dataSummary={dataSummary} />
    },
    {
      title: 'วิเคราะห์ผล',
      content: <Area student={student} mainData={data} dataArea={dataArea} />
    },

  ]

  const handleChangeTab = (event, newValue, pretab) => {

    setTabValue(newValue);

  };

  const onClickMarker = (property) => {
    if (!is_Click.current) {
      is_Click.current = true
      setModalSchool({
        visible: true,
        resource: 'school',
        ...property
      })
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onBack = () => {
    let _filter = { ...filter }
    if (_filter.view === _filter.minView) return;
    if (_filter.view === 'province') {
      _filter['view'] = 'country'
      _filter['province_name'] = 'ทั้งหมด'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    } else if (_filter.view === 'city') {
      _filter['view'] = 'province'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    }
    setFilter(_filter)
  }

  console.log("modalSchool", modalSchool);

  return (
    <Layoutas isHideFooter Role={['partner', 'eef', 'partner_province']}>
      <Modal
        width={'1000px'}
        bodyStyle={{ borderRadius: 24 }}
        title={null}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        visible={modalSchool.visible}
        centered={true}
        CancelText={'ปิด'}
        onCancel={() => {
          setModalSchool({ visible: false })
        }}
      >
        <div className='text-3xl'>โรงเรียน{modalSchool?.hostname} <span className='text-xl'></span></div>
        <div className='text-xl'>ที่อยู่ ตำบล{modalSchool?.subdistrict} อำเภอ{modalSchool?.district} จังหวัด{modalSchool?.province}</div>
        {/* <Divider style={{ margin: 8 }} />
        <div className='text-3xl m-2'>ได้รับทุนจากโครงการทุนเสมอภาค {0} คน</div> */}
        <Divider style={{ margin: 4 }} />

        <div className='grid grid-cols-12 gap-4 '>
          <div className='col-span-12 lg:col-span-6 flex-col'>
            <div className='relative w-full '>
              <div className='w-full flex flex-col rounded-lg p-2'>
                <div className='text-xl text-green-500 '>ข้อมูลพื้นฐาน</div>
                <div className='text-lg '>สังกัด :  {modalSchool?.sungkud} </div>
                <div className='text-lg '>ขนาดโรงเรียน :  {modalSchool?.school_size ? modalSchool?.school_size : '-'} </div>
                <div className='text-lg '>ภูมิภาค :  {modalSchool?.region_thai ? modalSchool?.region_thai : '-'} </div>
                {/* <div className='text-lg '>ลักษณะภูมิประเทศ :  {MAP_TEXT[modalSchool?.geographic_location]} </div> */}
                {/* <div className='text-lg '>ระดับชั้นที่เปิดสอน :  {modalSchool?.lowest_classes} - {modalSchool?.highest_classes}</div> */}
                <Divider style={{ margin: 4 }} />
                <div className='text-xl text-green-500 mt-8'>ข้อมูลนักเรียน</div>
                <div className='text-lg '>จำนวนนักเรียนทั้งหมด :   {(modalSchool?.total_kindergarten + modalSchool?.total_primary + modalSchool?.total_junior + modalSchool?.total_high)?.toLocaleString("en-US")} คน </div>
                <div className='text-lg '>อนุบาล :  {modalSchool?.total_kindergarten?.toLocaleString("en-US")} คน</div>
                <div className='text-lg '>ประถมศึกษา : {modalSchool?.total_primary?.toLocaleString("en-US")}  คน</div>
                <div className='text-lg '>มัธยมศึกษาตอนต้น :  {modalSchool?.total_junior?.toLocaleString("en-US")} คน</div>
                <div className='text-lg '>มัธยมศึกษาตอนปลาย :  {modalSchool?.total_high?.toLocaleString("en-US")} คน</div>
              </div>
            </div>
          </div>
          <div className='col-span-12 lg:col-span-6 flex-col'>
            <div className='relative w-full '>
              <div className='w-full flex flex-col rounded-lg p-2'>
                <div className='text-xl text-green-500 '>ข้อมูลครู</div>
                <div className='text-lg '>จำนวนครูทั้งหมด :  {modalSchool?.t_total ? modalSchool?.t_total?.toLocaleString("en-US") : '-'} คน</div>
                <div className='text-lg '>จำนวนครูผู้ชาย :  {modalSchool?.t_total_m ? modalSchool?.t_total_m?.toLocaleString("en-US") : '-'} คน</div>
                <div className='text-lg '>จำนวนครูผู้หญิง :  {modalSchool?.t_total_f ? modalSchool?.t_total_f?.toLocaleString("en-US") : '-'} คน</div>
                {/* <div className='text-lg '>ไฟฟ้า :  {modalSchool?.school_has_electricity ? 'โรงเรียนมีไฟฟ้าใช้' : 'โรงเรียนไม่มีไฟฟ้าใช้'}  </div>
                <div className='text-lg '>แหล่งน้ำ :  {modalSchool?.water_source} </div> */}
                <Divider style={{ margin: 4 }} />
                <div className='text-xl text-green-500 mt-8'>ข้อมูลทรัพยากร</div>
                <div className='text-lg '>ไฟฟ้า :  {modalSchool?.electricity ? (modalSchool?.electricity === 'true' ? 'มีไฟฟ้าใช้' : 'ไม่มีไฟฟ้าใช้') : '-'}  </div>
                <div className='text-lg '>แหล่งน้ำ :  {modalSchool ? modalSchool?.water_source : '-'} </div>
              </div>
            </div>
          </div>
          <div className='col-span-12 lg:col-span-6 flex-col'>
            <div className='relative w-full '>
              <div className='w-full flex flex-col rounded-lg p-2'>
                <Divider style={{ margin: 4 }} />
                <div className='text-xl text-green-500 mt-8'>ข้อมูลติดต่อ</div>
                <div className='text-lg '>email :  {modalSchool?.email ? modalSchool?.email : '-'}  </div>
                <div className='text-lg '>website :  {modalSchool?.website ? modalSchool?.website : '-'} </div>
                <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${modalSchool?.lat},${modalSchool?.lng}`} className='text-lg '>นำทางไปยัง Google Maps</a>
              </div>
            </div>
          </div>
        </div>

      </Modal>
      <div className='relative overflow-hidden'>
        <div className='w-[100vw] h-[100vh]'>
          <Mapbox

            id={'map-tsqp'}
            filter={filter}
            data={data_map}
            className="fullmap-cct-container"
            style={"border_basic"}
            openSideTab={open}
            onClickMarker={onClickMarker}
          />
          <div className='absolute top-[90px] left-2 z-1'>
            <LegendCard {...legend_data} />
            <div className='justify-left mt-2 mx-auto'>
              {filter.view !== filter.minView ? <button class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full" onClick={() => onBack()}>
                <ArrowBackIcon sx={{ fontSize: 18, color: 'white' }} /> ย้อนกลับ
              </button> : <></>}
            </div>
          </div>

          <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
            <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
              <span className='font-bold center mx-1 mb-2'>สัดส่วนจำนวนโรงเรียน</span>
              <div className='flex'>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#1b5e20]`}>
                  </div>
                  <div class="font-bold text-left">
                    มาก
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#388e3c]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#66bb6a]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#a5d6a7]`}>
                  </div>
                  <div class="font-bold text-right">
                    น้อย
                  </div>
                </div>
                <div className='ml-4  flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#94a3b8]`}>
                  </div>
                  <div class="font-bold text-center">
                    ไม่มีข้อมูล
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='absolute bottom-[10px] left-[470px] z-1'>
            <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
              <span className='font-bold center mx-1 mb-2'>โรงเรียนจำแนกตามประเภท</span>
              <div className="flex flex-row">
                <div className="flex flex-row text-center mr-2">
                  <img src={marker_obec} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>Advantaged.</span>
                </div>
                <div className="flex flex-row text-center mr-2">
                  <img src={marker_bpp} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>Average.</span>
                </div>
                <div className="flex flex-row text-center mr-2">
                  <img src={marker_opec} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>Disadvantaged.</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Sidetab
          title="การวิเคราะห์เครื่องมือ FSQL ในโรงเรียนครูรัก(ษ์)ถิ่นที่เข้าร่วมโครงการ"
          tab={tab_content}
          open={open}
          tabvalue={tabvalue}
          setOpen={setOpen}
          handleChangeTab={handleChangeTab}
          student={student}
          mainData={data}

        />
      </div>
    </Layoutas>
  )

}

export default FullMap
