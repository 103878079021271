import React, { useState, useEffect, useRef } from "react";
import schoolIcon from '../../../../images/cct-3.png'
import fsqlFormIcon from '../../../../images/evaluation 1.png'
import school_s_Icon from '../../../../images/krt-school-s.png'
import school_l_Icon from '../../../../images/krt-school-l.png'
import school_x_Icon from '../../../../images/krt-school-x.png'
import school_xl_Icon from '../../../../images/krt-school-xl.png'

const Summary = ({ mainData, dataSummary }) => {

  let dataTypeSchool = [
    {
      type: 'รร.ขนาดเล็ก',
      school: dataSummary.find(item => item.size === 'เล็ก')?.school,
      icon: school_s_Icon
    },
    {
      type: 'รร.ขนาดกลาง',
      school: dataSummary.find(item => item.size === 'กลาง')?.school,
      icon: school_l_Icon
    },
    {
      type: 'รร.ขนาดใหญ่',
      school: dataSummary.find(item => item.size === 'ใหญ่')?.school,
      icon: school_x_Icon
    },
    {
      type: 'รร.ขนาดใหญ่พิเศษ',
      school: dataSummary.find(item => item.size === 'ใหญ่พิเศษ')?.school,
      icon: school_xl_Icon
    },
  ]
  return (
    <>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="w-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-4 rounded-xl shadow-xl col-span-2 md:col-span-1">
          <div className="flex justify-around items-center">
            <img src={schoolIcon} width={60} height={60} />
            <div>
              <div className="text-lg font-bold">โรงเรียนครูรัก(ษ์)ถิ่นทั้งหมด</div>
              <div className="text-right"><span className="text-2xl text-[#171FD4] font-bold">741 </span> โรงเรียน</div>
            </div>
          </div>
        </div>
        <div className="w-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-4 rounded-xl shadow-xl col-span-2 md:col-span-1">
          <div className="flex justify-around items-center">
            <img src={fsqlFormIcon} width={60} height={60} />
            <div>
              <div className="text-lg font-bold">โรงเรียนครูรัก(ษ์)ถิ่นที่ทำ FSQL</div>
              <div className="text-right"><span className="text-2xl text-[#17D457] font-bold">{mainData.length} </span> โรงเรียน</div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-10 gap-4">
        <div className="col-span-10  md:col-span-4">
          <div className="w-full h-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-4 rounded-xl shadow-xl">
            <div className="text-2xl font-bold mb-4">จำแนกตามขนาดโรงเรียน</div>
            {dataTypeSchool.map((item, idx) =>
              <div className="flex justify-around items-center gap-4 mb-6">
                <img className="text-right" src={item.icon} width={60} height={60} />
                <div className="text-left w-[60%]">
                  <div className="text-md font-bold">{item.type}</div>
                  <div>จำนวนโรงเรียน</div>
                  <div className=""><span className="text-2xl text-[#465B8F] font-bold">{item.school} </span> รร</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-span-10  md:col-span-6">
          <div className="w-full h-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-4 rounded-xl shadow-xl">
            <div className="text-2xl font-bold mb-4">จำแนกตามประเภทโรงเรียน</div>
            <div className="mb-4">โดยใน 275 โรงมีโรงเรียนที่สอนเฉพาะก่อนประถมถึงประถม 150 โรงเรียน และโรงเรียนที่สอนตั้งแต่ก่อนประถมถึงมัธยม 125 โรงเรียน</div>
            <div className="flex justify-around items-center gap-4 mb-6">
              <img className="text-right" src={school_l_Icon} width={60} height={60} />
              <div className="text-left w-[60%]">
                <div className="text-md font-bold">ก่อนประถม - ประถมศึกษา</div>
                <div>จำนวนโรงเรียน</div>
                <div className=""><span className="text-2xl text-[#465B8F] font-bold">150 </span> รร</div>
              </div>
            </div>
            <div className="flex justify-around items-center gap-4 mb-6">
              <img className="text-right" src={school_xl_Icon} width={60} height={60} />
              <div className="text-left w-[60%]">
                <div className="text-md font-bold">ก่อนประถม - มัธยมศึกษา</div>
                <div>จำนวนโรงเรียน</div>
                <div className=""><span className="text-2xl text-[#465B8F] font-bold">125 </span> รร</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
