import React, { useState, useEffect, useRef } from "react";
import schoolIcon from "../../../../images/cct-3.png";
import fsqlFormIcon from "../../../../images/evaluation 1.png";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Area = ({mainData,student,dataArea}) => {

  console.log("dataArea",dataArea);

  var chart1 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: false,
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.0f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        colors: ["#C9EAD4", "#88D1A1", "#67C587"],
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b><br>{point.percentage:.0f} %",
          distance: 5,
          filter: {
            property: "percentage",
            operator: ">",
            value: 4,
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "ร้อยละ",
        colorByPoint: true,
        data: [
          {
            name: "Advantaged",
            y: dataArea.schoolType.advantaged,
          },
          {
            name: "Average",
            y: dataArea.schoolType.average,
          },
          {
            name: "Disadvantaged",
            y: dataArea.schoolType.disAdvantaged,
          },
        ],
      },
    ],
  };

  var chart2 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: false,
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.0f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        colors: ["#C9EAD4", "#88D1A1", "#67C587"],
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b><br>{point.percentage:.0f} %",
          distance: 5,
          filter: {
            property: "percentage",
            operator: ">",
            value: 4,
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "ร้อยละ",
        colorByPoint: true,
        data: [
          {
            name: "Advantaged",
            y: dataArea.studentType.advantaged,
          },
          {
            name: "Average",
            y: dataArea.studentType.average,
          },
          {
            name: "Disadvantaged",
            y: dataArea.studentType.disAdvantaged,
          },
        ],
      },
    ],
  };

  var chart3 = {
    chart: {
      type: "column",
    },
    title: false,
    xAxis: {
      categories: ["Advantaged", "Average", "Disadvantaged"],
    },
    yAxis: {
      min: 0,
			max: 100,
			labels: {
				formatter: function() {
					return this.value + '%';
				}
			},
      title: {
        text: "Percents",
      },
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: "percent",
        dataLabels: {
          enabled: true,
          formatter: function() {
            return this.percentage.toFixed(2) + '%';
          },
          color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
          style: {
              textShadow: '0 0 3px black'
          }
      }
      },
 
    },
    series: [
      {
        name: "เล็ก",
        color: "#67C587",
        data: [dataArea.schoolSizeType.small.advantaged, dataArea.schoolSizeType.small.average, dataArea.schoolSizeType.small.disAdvantaged],
      },
      {
        name: "กลาง",
        color: "#88D1A1",
        data: [dataArea.schoolSizeType.middle.advantaged, dataArea.schoolSizeType.middle.average, dataArea.schoolSizeType.middle.disAdvantaged],
      },
      {
        name: "ใหญ่",
        color: "#C9EAD4",
        data: [dataArea.schoolSizeType.large.advantaged, dataArea.schoolSizeType.large.average, dataArea.schoolSizeType.large.disAdvantaged],
      },
      {
        name: "ใหญ่พิเศษ",
        color: "#059F38",
        data: [dataArea.schoolSizeType.extra.advantaged, dataArea.schoolSizeType.extra.average, dataArea.schoolSizeType.extra.disAdvantaged],
      },
    ],
  };

  var chart4 = {
    chart: {
      type: "column",
    },
    title: false,
    xAxis: {
      categories: ["Advantaged", "Average", "Disadvantaged"],
    },
    yAxis: {
      min: 0,
      title: {
        text: "Percents",
      },
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.0f}%</b> <br/>',
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: "percent",
        dataLabels: {
          enabled: true,
          formatter: function() {
            return this.percentage.toFixed(1) + '%';
          },
          color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
          style: {
              textShadow: '0 0 3px black'
          }
      }
      },
    },
    series: [
      {
        name: "จน",
        color: "#67C587",
        data: [39.6, 43.1, 47.2],
      },
      {
        name: "ไม่จน",
        color: "#88D1A1",
        data: [60.4, 56.9, 52.8],
      },
    ],
  };

  var chart5 = {
    chart: {
      type: "column",
    },
    title: false,
    xAxis: {
      categories: ["Advantaged", "Average", "Disadvantaged"],
    },
    yAxis: {
      min: 0,
      title: {
        text: "Percents",
      },
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: "percent",
        dataLabels: {
          enabled: true,
          formatter: function() {
            return this.percentage.toFixed(0) + '%';
          },
          color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
          style: {
              textShadow: '0 0 3px black'
          }
      }
      },
    },
    series: [
      {
        name: "High",
        color: "#67C587",
        data: [dataArea.schoolOutcome.high.advantaged, dataArea.schoolOutcome.high.average, dataArea.schoolOutcome.high.disAdvantaged],
      },
      {
        name: "Average",
        color: "#88D1A1",
        data: [dataArea.schoolOutcome.average.advantaged, dataArea.schoolOutcome.average.average, dataArea.schoolOutcome.average.disAdvantaged],
      },
      {
        name: "Low",
        color: "#C9EAD4",
        data: [dataArea.schoolOutcome.low.advantaged, dataArea.schoolOutcome.low.average, dataArea.schoolOutcome.low.disAdvantaged],
      },
    ],
  };

  let mockUpDataFsql = [
    {
      title: "กลุ่มโรงเรียน Advantaged",
      value1: numberWithCommas(dataArea.schoolType.advantaged),
    },
    {
      title: "กลุ่มโรงเรียน Average",
      value1: numberWithCommas(dataArea.schoolType.average),
    },
    {
      title: "กลุ่มโรงเรียน Disadvantaged",
      value1: numberWithCommas(dataArea.schoolType.disAdvantaged),
    },
  ];

  let mockUpDataFsql2 = [
    {
      title: "นักเรียนกลุ่มโรงเรียน Advantaged",
      value1: numberWithCommas(dataArea.studentType.advantaged),
    },
    {
      title: "นักเรียนกลุ่มโรงเรียน Average",
      value1: numberWithCommas(dataArea.studentType.average),
    },
    {
      title: "นักเรียนกลุ่มโรงเรียน Disadvantaged",
      value1: numberWithCommas(dataArea.studentType.disAdvantaged),
    },
  ];

  let mockUpDataFsql3 = [
    {
      title: "Advantaged",
      small: numberWithCommas(dataArea.schoolSizeType.small.advantaged || 0),
      middle:numberWithCommas(dataArea.schoolSizeType.middle.advantaged || 0),
      large: numberWithCommas(dataArea.schoolSizeType.large.advantaged || 0),
      extra: numberWithCommas(dataArea.schoolSizeType.extra.advantaged || 0),
    },
    {
      title: "Average",
      small: numberWithCommas(dataArea.schoolSizeType.small.average || 0),
      middle:numberWithCommas(dataArea.schoolSizeType.middle.average || 0),
      large: numberWithCommas(dataArea.schoolSizeType.large.average || 0),
      extra: numberWithCommas(dataArea.schoolSizeType.extra.average || 0),
    },
    {
      title: "Disadvantaged",
      small: numberWithCommas(dataArea.schoolSizeType.small.disAdvantaged || 0),
      middle:numberWithCommas(dataArea.schoolSizeType.middle.disAdvantaged || 0),
      large: numberWithCommas(dataArea.schoolSizeType.large.disAdvantaged || 0),
      extra: numberWithCommas(dataArea.schoolSizeType.extra.disAdvantaged || 0),
    },
  ];

  return (
    <>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="w-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-4 rounded-xl shadow-xl col-span-2 md:col-span-1">
          <div className="flex justify-around items-center">
            <img src={schoolIcon} width={60} height={60} />
            <div>
              <div className="text-lg font-bold">จำนวนโรงเรียน</div>
              <div className="text-right">
                <span className="text-2xl text-[#171FD4] font-bold">{mainData.length} </span>{" "}
                โรงเรียน
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-4 rounded-xl shadow-xl col-span-2 md:col-span-1">
          <div className="flex justify-around items-center">
            <img src={fsqlFormIcon} width={60} height={60} />
            <div>
              <div className="text-lg font-bold">จำนวนนักเรียน</div>
              <div className="text-right">
                <span className="text-2xl text-[#17D457] font-bold">
                  {numberWithCommas(student)}{" "}
                </span>{" "}
                คน
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto rounded-xl shadow-xl mb-4">
        <div className="text-xl font-bold mb-4 text-center">
          โรงเรียนจำแนกตามประเภท
        </div>
        <div className="grid grid-cols-2">
          <div className="border-r-2 border-gray-200 col-span-2 md:col-span-1">
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={"chart"}
              options={chart1}
              containerProps={{ style: { height: "100%" } }}
            />
          </div>
          <div className="p-4 col-span-2 md:col-span-1">
            <div className="grid grid-cols-2 items-center border-b-[1px] border-b-solid border-gray-200 p-[10px] text-center">
              <div className="text-black font-bold">ประเภท</div>
              <div className="text-black font-bold">จำนวน (รร.)</div>
            </div>

            {mockUpDataFsql.map((item, idx) => (
              <div className="grid grid-cols-2 items-center border-b-[1px] border-b-solid border-gray-200 p-[10px] text-center">
                <div className="text-black">{item.title}</div>
                <div className="text-black">{item.value1}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full h-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-4 rounded-xl shadow-xl mb-4">
        <div className="text-xl font-bold mb-4 text-center">
          จำนวนนักเรียนแบ่งตามประเภทโรงเรียน
        </div>

        <div className="grid grid-cols-2 col-span-2">
          <div className="border-r-2 border-gray-200 col-span-2 md:col-span-1">
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={"chart"}
              options={chart2}
              containerProps={{ style: { height: "100%" } }}
            />
          </div>
          <div className="p-4 col-span-2 md:col-span-1">
            <div className="grid grid-cols-2 items-center border-b-[1px] border-b-solid border-gray-200 p-[10px] text-center">
              <div className="text-black font-bold">ประเภท</div>
              <div className="text-black font-bold">จำนวน (คน)</div>
            </div>

            {mockUpDataFsql2.map((item, idx) => (
              <div className="grid grid-cols-2 items-center border-b-[1px] border-b-solid border-gray-200 p-[10px] text-center">
                <div className="text-black">{item.title}</div>
                <div className="text-black">{item.value1}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full h-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-4 rounded-xl shadow-xl mb-4">
        <div className="text-xl font-bold text-center">
          ประเภทโรงเรียนจำแนกตามขนาดโรงเรียน
        </div>
        <div className="text-center mb-4">
          โรงเรียน 46.15% ในกลุ่ม Disadvantaged เป็นโรงเรียนขนาดเล็ก
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="border-r-2 border-gray-200">
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={"chart"}
              options={chart3}
              containerProps={{ style: { height: "100%" } }}
            />
          </div>
          <div className="p-4">
            <div className="grid grid-cols-5 items-center border-b-[1px] border-b-solid border-gray-200 p-[10px] text-center">
              <div className="text-black font-bold">ประเภท</div>
              <div className="text-black font-bold">เล็ก</div>
              <div className="text-black font-bold">กลาง</div>
              <div className="text-black font-bold">ใหญ่</div>
              <div className="text-black font-bold">ใหญ่พิเศษ</div>
            </div>

            {mockUpDataFsql3.map((item, idx) => (
              <div className="grid grid-cols-5 items-center border-b-[1px] border-b-solid border-gray-200 p-[10px] text-center">
                <div className="text-black">{item.title}</div>
                <div className="text-black">{item.small}</div>
                <div className="text-black">{item.middle}</div>
                <div className="text-black">{item.large}</div>
                <div className="text-black">{item.extra}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full h-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-4 rounded-xl shadow-xl mb-4">
        <div className="text-xl font-bold text-center">
          ประเภทโรงเรียนจำแนกตามประเภทความยากจน
        </div>
        <div className="text-center mb-4">
          {" "}
          สัดส่วนนักเรียนยากจน (รวมจนพิเศษ) ในกลุ่ม Disadvantaged เฉลี่ย 47%
        </div>
        <div className="border-r-2 border-gray-200">
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"chart"}
            options={chart4}
            containerProps={{ style: { height: "100%" } }}
          />
        </div>
      </div>

      <div className="w-full h-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-4 rounded-xl shadow-xl mb-4">
        <div className="text-xl font-bold text-center">
          Student Outcome vs กลุ่มโรงเรียน{" "}
        </div>
        <div className="text-center">
          โรงเรียนในกลุ่ม Advantaged ส่วนใหญ่มี student outcome ในระดับดี
        </div>
        <div className="text-center mb-4">
          โรงเรียนในกลุ่ม Disadvantaged ส่วนใหญ่มี student outcome ที่ไม่ดี
        </div>

        <div className="border-r-2 border-gray-200">
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"chart"}
            options={chart5}
            containerProps={{ style: { height: "100%" } }}
          />
        </div>

        <div className="text-center">
          เห็นได้ว่าโรงเรียนที่มีค่าดัชนีอยู่ในระดับต่ำ (Disadvantaged)
          โดยเฉลี่ยแล้วจะมี student learning outcome ในระดับต่ำด้วย
          ในขณะที่โรงเรียนที่มีค่าดัชนีอยู่ในระดับสูง (Advantaged)
          โดยเฉลี่ยแล้วจะมี student learning outcome ในระดับสูง
        </div>
      </div>
    </>
  );
};

export default Area;
